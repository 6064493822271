import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';
import {useParams} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {JumpingIcon} from '@renofi/components/src/Icons';
import {getTimeDiff, formatAMPM} from '@renofi/utils/src/time';
import {formatDate} from '@renofi/utils/src/date';

const ScheduleSuccess = ({analyticsPrefix}) => {
  const {from, to} = useParams();
  const fromDate = new Date(Number(from));
  const formattedDate = formatDate(fromDate);
  const period = getTimeDiff(from, to);
  const time = formatAMPM(fromDate);

  function getMessage() {
    if (from && to) {
      return `An Advisor will reach out to you for a ${period} minute call on ${formattedDate} at ${time}`;
    } else {
      return `An Advisor will reach out to you.`;
    }
  }

  useEffect(() => {
    sendEvent(`${analyticsPrefix}/Schedule-Success-Page`);
  }, []);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={['100%', 800]}
      px={[25, 0]}
      mx="auto"
      my={[40, 20]}>
      <Text
        mb={30}
        mt={[-10, 20]}
        fontSize={[24, 28]}
        lineHeight={['36px', '42px']}>
        You are all set! <br />
        {getMessage()}
      </Text>
      <JumpingIcon />
    </Flex>
  );
};

ScheduleSuccess.propTypes = {
  analyticsPrefix: PropTypes.string,
};

export default ScheduleSuccess;
