import {logErorr} from '@renofi/analytics';

export default ({graphQLErrors, operation, networkError}) => {
  const {operationName, variables} = operation || {};
  const extra = {operationName, variables};

  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path, extensions}) => {
      const err = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations,
      )}, Path: ${path}, Raw: ${JSON.stringify(extensions)}`;
      logErorr(err, extra);
    });
  if (networkError) {
    const err = `[Network error]: ${JSON.stringify(networkError)}`;
    logErorr(err, extra);
  }
};
