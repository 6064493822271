import {ApolloLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {noop} from 'lodash';

import {getItem, toggleSystemQuery, logGraphQLError} from '@renofi/utils';

/**
 * ============ Apollo Link
 */
// Log any GraphQL errors or network error that occurred
export const graphQLErrorLink = onError(logGraphQLError);

export const authLink = setContext((_, {headers, ...context}) => {
  const serviceAccountId = process.env.REACT_APP_SERVICE_ACCOUNT_ID;
  const jwt = getItem('jwt');

  return {
    ...context,
    headers: {
      ...headers,
      authorization: jwt || '',
      ...(serviceAccountId ? {'X-Service-Account-ID': serviceAccountId} : {}),
    },
  };
});

export const watchQueryLink = new ApolloLink((operation, forward) => {
  // const definitions = operation?.query?.definitions || [];
  // const isMutation = definitions[0]?.operation === 'mutation';
  toggleSystemQuery(operation?.operationName, true);
  return forward(operation).map((data) => {
    toggleSystemQuery(operation?.operationName, false);

    return data;
  });
});

// Exposed for testing
export const errorHandler =
  (onAuthError = noop) =>
  ({graphQLErrors = [], operation}) => {
    const {operationName = 'Unknown operation', query} = operation || {};
    const defs = query?.definitions || [];
    const isMutation = defs.some((d) => d.operation === 'mutation');

    graphQLErrors.forEach(({message}) => {
      if (
        message === 'UNAUTHENTICATED_ERROR' ||
        message === 'UNAUTHORIZED_ERROR' ||
        message === 'Refresh token is invalid'
      ) {
        onAuthError({isMutation, operationName, message});
      }
    });
  };
