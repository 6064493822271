import {gql} from '@apollo/client';

import LEAD from '../partials/lead';

export const UPDATE_SCENARIO = gql`
  mutation updateScenario(
    $additionalRenovationDetails: String
    $afterRenovatedValue: Int
    $agreedToContractorCommunicationAt: DateTime
    $alimonyPayment: Int
    $alreadyStartedPropertyStateDetails: String
    $annualIncomePretax: Int
    $childSupportPayment: Int
    $creditCardPayment: Int
    $estimatedDownpayment: Int
    $selfReportedCreditScore: CreditScoreEnum
    $firstName: String
    $hasLateMortgagePayments: Boolean
    $homeValue: Int
    $propertyPurchasePrice: Int
    $renovationCost: Int
    $id: ID!
    $isLivingInPropertyDuringReno: Boolean
    $loanSubject: LoanSubjectEnum
    $isSubcontractingMany: Boolean
    $isLicensedContractor: Boolean
    $loanAmountDesiredForRenovation: Int
    $monthlyIncomeAlimony: Int
    $monthlyIncomeChildSupport: Int
    $firstMortgageBalance: Int
    $firstMortgageRate: Float
    $isWorkingWithCobrandingSourcedContractor: Boolean
    $personalLoanPayment: Int
    $primaryMortgagePayment: Int
    $propertyStructureType: String
    $propertyUseType: String
    $recentBankruptcy: Boolean
    $renovationIdealStart: RenovationStartEnum
    $renovationPerformedByType: RenovationPerformedByEnum
    $secondMortgageBalance: Int
    $secondaryMortgagePayment: Int
    $selfReportedOrigin: SelfReportedOrigin
    $studentLoanBalance: Int
    $studentLoanPayment: Int
    $studentLoanPrePandemicPayment: Int
    $vehiclePayment: Int
    $subcontractorsSecured: Boolean
    $hasRenovationEstimate: Boolean
    $subcontractorsIdentified: Boolean
    $isSelfEmploymentIncomeStable: Boolean
    $employmentStatus: EmploymentStatusEnum
    $workingLongTerm: Boolean
    $estimatedDaysUntilClosingPurchase: EstimatedDaysUntilClosingPurchaseEnum
    $stageOfPurchase: StageOfPurchaseEnum
    $estimatedProjectSize: EstimatedProjectSizeEnum
    $hasCommissionIncome: Boolean
    $documentsOnHand: [DocumentsOnHandEnum!]
    $prequalLetterRequested: Boolean
    $presentedTurndownOptions: [TurndownOptionsPresentedEnum!]
    $preExistingStructuresOnLandPresent: Boolean
    $demolishExistingStructure: Boolean
    $anythingLeftFromOriginalStructure: Boolean
    $teardownOfExistingStructure: Boolean
    $propertyOwnershipStatus: PropertyOwnershipStatusEnum
    $existingStructuresPlan: ExistingStructuresPlanTypeEnum
    $servedMilitary: Boolean
    $contractorRequestedAt: DateTime
    $lendingTreeSelectedAmount: Int
    $lendingTreeSelectedApr: Float
    $lendingTreeSelectedTerm: Int
    $lendingTreeSelectedLoanProductId: Int
    $lendingTreeSelectedFormName: String
  ) {
    updateScenario(
      additionalRenovationDetails: $additionalRenovationDetails
      afterRenovatedValue: $afterRenovatedValue
      agreedToContractorCommunicationAt: $agreedToContractorCommunicationAt
      alimonyPayment: $alimonyPayment
      alreadyStartedPropertyStateDetails: $alreadyStartedPropertyStateDetails
      annualIncomePretax: $annualIncomePretax
      childSupportPayment: $childSupportPayment
      creditCardPayment: $creditCardPayment
      selfReportedCreditScore: $selfReportedCreditScore
      firstName: $firstName
      hasLateMortgagePayments: $hasLateMortgagePayments
      homeValue: $homeValue
      estimatedDownpayment: $estimatedDownpayment
      propertyPurchasePrice: $propertyPurchasePrice
      id: $id
      isWorkingWithCobrandingSourcedContractor: $isWorkingWithCobrandingSourcedContractor
      isLivingInPropertyDuringReno: $isLivingInPropertyDuringReno
      loanSubject: $loanSubject
      isSubcontractingMany: $isSubcontractingMany
      isLicensedContractor: $isLicensedContractor
      loanAmountDesiredForRenovation: $loanAmountDesiredForRenovation
      monthlyIncomeAlimony: $monthlyIncomeAlimony
      monthlyIncomeChildSupport: $monthlyIncomeChildSupport
      firstMortgageBalance: $firstMortgageBalance
      firstMortgageRate: $firstMortgageRate
      personalLoanPayment: $personalLoanPayment
      renovationCost: $renovationCost
      primaryMortgagePayment: $primaryMortgagePayment
      propertyStructureType: $propertyStructureType
      propertyUseType: $propertyUseType
      recentBankruptcy: $recentBankruptcy
      renovationIdealStart: $renovationIdealStart
      renovationPerformedByType: $renovationPerformedByType
      secondMortgageBalance: $secondMortgageBalance
      secondaryMortgagePayment: $secondaryMortgagePayment
      studentLoanBalance: $studentLoanBalance
      studentLoanPayment: $studentLoanPayment
      studentLoanPrePandemicPayment: $studentLoanPrePandemicPayment
      vehiclePayment: $vehiclePayment
      subcontractorsSecured: $subcontractorsSecured
      hasRenovationEstimate: $hasRenovationEstimate
      subcontractorsIdentified: $subcontractorsIdentified
      isSelfEmploymentIncomeStable: $isSelfEmploymentIncomeStable
      employmentStatus: $employmentStatus
      workingLongTerm: $workingLongTerm
      estimatedDaysUntilClosingPurchase: $estimatedDaysUntilClosingPurchase
      stageOfPurchase: $stageOfPurchase
      estimatedProjectSize: $estimatedProjectSize
      hasCommissionIncome: $hasCommissionIncome
      selfReportedOrigin: $selfReportedOrigin
      documentsOnHand: $documentsOnHand
      prequalLetterRequested: $prequalLetterRequested
      presentedTurndownOptions: $presentedTurndownOptions
      preExistingStructuresOnLandPresent: $preExistingStructuresOnLandPresent
      demolishExistingStructure: $demolishExistingStructure
      anythingLeftFromOriginalStructure: $anythingLeftFromOriginalStructure
      teardownOfExistingStructure: $teardownOfExistingStructure
      existingStructuresPlan: $existingStructuresPlan
      propertyOwnershipStatus: $propertyOwnershipStatus
      servedMilitary: $servedMilitary
      contractorRequestedAt: $contractorRequestedAt
      lendingTreeSelectedAmount: $lendingTreeSelectedAmount
      lendingTreeSelectedApr: $lendingTreeSelectedApr
      lendingTreeSelectedTerm: $lendingTreeSelectedTerm
      lendingTreeSelectedLoanProductId: $lendingTreeSelectedLoanProductId
      lendingTreeSelectedFormName: $lendingTreeSelectedFormName
    ) {
      lead {
        ${LEAD}
      }
      loanProductMatches {
        maxLoanAmountOffered
        minMonthlyPayment
        refinancedMonthlyMortgagePayments
        renovationFractionMinMonthlyPayment
        offeredLoanAmountExcludingRefinance
        product {
          allowedCombinations
          cashDisbursementType
          id
          interestOnly
          lender {
            name
            code
            id
          }
          lenderId
          maxArvCltv
          maxLoanAmount
          maxPeakCltv
          minCreditScore
          minLoanAmount
          name
          productType
          rateType
          rates
          refinanceRequired
          repaymentPenalty
          repaymentPeriod
          maxDti
        }
      }
      loanProductMatchErrors
    }
  }
`;
