import {gql} from '@apollo/client';

export const SUBMIT_SCENARIO = gql`
  mutation submitScenario(
    $afterRenovatedValue: Int
    $conversationId: String
    $selfReportedCreditScore: CreditScoreEnum
    $email: String!
    $firstName: String!
    $hasNewsletterConsent: Boolean
    $homeValue: Int
    $loanSubject: LoanSubjectEnum!
    $lastName: String!
    $loanAmountDesiredForRenovation: Int
    $firstMortgageBalance: Int
    $firstMortgageRate: Float
    $phone: String
    $propertyPurchasePrice: Int
    $renovationIdealStart: RenovationStartEnum
    $zipCode: String
    $isSelfEmploymentIncomeStable: Boolean
    $employmentStatus: EmploymentStatusEnum
    $workingLongTerm: Boolean
    $renovationCost: Int
    $estimatedDownpayment: Int
    $stageOfPurchase: StageOfPurchaseEnum
    $estimatedProjectSize: EstimatedProjectSizeEnum
    $hasCommissionIncome: Boolean
    $utmTags: UtmTags
    $sourceLenderCode: String
    $leadExternalOriginType: LeadExternalOriginEnum
    $propertyOwnershipStatus: PropertyOwnershipStatusEnum
    $location: LocationInputObject
    $experimentalId: String
  ) {
    submitScenario(
      afterRenovatedValue: $afterRenovatedValue
      conversationId: $conversationId
      selfReportedCreditScore: $selfReportedCreditScore
      email: $email
      firstName: $firstName
      hasNewsletterConsent: $hasNewsletterConsent
      homeValue: $homeValue
      loanSubject: $loanSubject
      lastName: $lastName
      loanAmountDesiredForRenovation: $loanAmountDesiredForRenovation
      firstMortgageBalance: $firstMortgageBalance
      firstMortgageRate: $firstMortgageRate
      phone: $phone
      propertyPurchasePrice: $propertyPurchasePrice
      renovationIdealStart: $renovationIdealStart
      zipCode: $zipCode
      isSelfEmploymentIncomeStable: $isSelfEmploymentIncomeStable
      employmentStatus: $employmentStatus
      workingLongTerm: $workingLongTerm
      renovationCost: $renovationCost
      estimatedDownpayment: $estimatedDownpayment
      stageOfPurchase: $stageOfPurchase
      estimatedProjectSize: $estimatedProjectSize
      hasCommissionIncome: $hasCommissionIncome
      utmTags: $utmTags
      sourceLenderCode: $sourceLenderCode
      leadExternalOriginType: $leadExternalOriginType
      propertyOwnershipStatus: $propertyOwnershipStatus
      location: $location
      experimentalId: $experimentalId
    ) {
      jwt
      lender {
        name
        code
      }
      lead {
        calculatorToken
        id
        medal
        firstMortgageBalance
        stars
        state
        hasNewsletterConsent
        sourceLenderCode
        leadExternalOriginType
        brokerSourced
        experimentalId
      }
    }
  }
`;
